import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { customSelectStyle } from "../../../services/general";
import classAPI from "../../../api/classApi";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import EmptyState from "../components/EmptyState";
import evaluationApi from "../../../api/evaluationApi";
import { format, startOfMonth } from "date-fns";
import { Modal } from "react-bootstrap";
import Basic from "./Basic";
import Advance from "./Advance";

const columns = [
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Date",
        accessor: "evaluation.date",
        Cell: ({ value }) => {
            return format(new Date(value), "yyyy-MM-dd");
        },
    },
    {
        Header: "Action",
    },
];

const Evaluation = () => {
    const [date, setDate] = useState(new Date());
    const [classOption, setClassOption] = useState([]);
    const [selectedClass, setSelectedClass] = useState();
    const [reportList, setReportList] = useState([]);

    const [viewReport, setViewReport] = useState({
        show: false,
        data: undefined,
    });

    const data = useMemo(() => {
        const array = [];
        for (let data of reportList) {
            if (data.evaluation.length > 0) {
                for (let evaluation of data.evaluation) {
                    array.push({
                        name: data.name,
                        evaluation,
                    });
                }
            } else {
                array.push({
                    name: data.name,
                    evaluation: {},
                });
            }
        }
        return array;
    }, [reportList]);

    const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, usePagination);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
        setPageSize,
    } = tableInstance;
    const { globalFilter, pageIndex, pageSize } = state;

    useEffect(() => {
        getClassOption();
    }, []);

    useEffect(() => {
        classOption.length > 0 && setSelectedClass(classOption[0].value);
    }, [classOption]);

    useEffect(() => {
        getReportList();
    }, [selectedClass, date]);

    const getClassOption = async () => {
        try {
            const res = await classAPI.classList();
            if (res.success) {
                const array = res.data.map((ele) => {
                    return { value: ele.id, label: ele.name };
                });
                setClassOption(array);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getReportList = async () => {
        try {
            if (selectedClass) {
                const res = await evaluationApi.getEvaluation(
                    format(startOfMonth(date), "yyyy-MM-dd"),
                    Number(selectedClass)
                );
                if (res.success) {
                    console.log(res.data);
                    setReportList(res.data);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div className="page-title flex-wrap">
                <div className="d-flex gap-5 w-50">
                    <div>
                        <h6 className="form-label ">Date</h6>
                        <DatePicker
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            selected={date}
                            onChange={(date) => {
                                setDate(date);
                            }}
                            className="form-control"
                        />
                    </div>
                    <div className="w-50">
                        <h6 className="form-label">Class</h6>
                        <Select
                            styles={customSelectStyle()}
                            options={classOption}
                            onChange={(e) => setSelectedClass(e.value)}
                            value={classOption.filter(({ value }) => value === selectedClass)}
                        />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 wow fadeInUp " data-wow-delay="1.5s">
                {data.length > 0 ? (
                    <div className="table-responsive full-data">
                        <div className="dataTables_wrapper no-footer">
                            <table
                                {...getTableProps()}
                                className="shadow-sm table-responsive-lg table display dataTablesCard student-tab dataTable no-footer"
                            >
                                <thead>
                                    {headerGroups.map((headerGroup, index) => {
                                        return (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                {headerGroup.headers.map((column, index2) => (
                                                    <th {...column.getHeaderProps()} key={index2}>
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, index) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} key={index}>
                                                {row.cells.map((cell, index2) => {
                                                    if (cell.column.id.toLowerCase() === "action") {
                                                        return (
                                                            <td key={index2}>
                                                                {cell.row.original.evaluation.id && (
                                                                    <div className="d-flex">
                                                                        <div
                                                                            className="icon-box icon-box-sm bg-light me-2"
                                                                            style={{ userSelect: "none" }}
                                                                            onClick={() => {
                                                                                console.log(cell.row.original);
                                                                                setViewReport({
                                                                                    show: true,
                                                                                    data: cell.row.original,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className="material-symbols-outlined"
                                                                                style={{
                                                                                    fontSize: 8,
                                                                                    color: "#4D44B5",
                                                                                }}
                                                                            >
                                                                                visibility
                                                                            </i>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td key={index2} {...cell.getCellProps()}>
                                                                {cell.value ? cell.render("Cell") : "-"}
                                                            </td>
                                                        );
                                                    }
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <EmptyState />
                )}
            </div>
            <Modal
                centered
                size="lg"
                show={viewReport.show}
                onHide={() => {
                    setViewReport({ show: false, data: undefined });
                }}
            >
                <Modal.Header closeButton>
                    <h4>Evaluation Report</h4>
                </Modal.Header>
                <Modal.Body>
                    {viewReport.data && (
                        <>
                            <div className="row">
                                <div className="col-4">
                                    <div className="flex-column d-flex col">
                                        <label className="text-grey font-w500 ">Name</label>
                                        <label className="text-black font-w500 fs-6" style={{ marginTop: -5 }}>
                                            {viewReport.data.name}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-column d-flex col">
                                        <label className="text-grey font-w500 ">Date</label>
                                        <label className="text-black font-w500 fs-6" style={{ marginTop: -5 }}>
                                            {format(new Date(viewReport.data.evaluation.date), "yyyy-MM-dd")}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="flex-column d-flex col">
                                        <label className="text-grey font-w500 ">Type</label>
                                        <label className="text-black font-w500 fs-6" style={{ marginTop: -5 }}>
                                            {viewReport.data.evaluation.type}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {viewReport.data.evaluation.type === "Basic" ? (
                                <Basic basic={viewReport.data.evaluation.basic} />
                            ) : (
                                <Advance
                                    image={viewReport.data.evaluation.image}
                                    subject={viewReport.data.evaluation.subject}
                                    book_name={viewReport.data.evaluation.book_name}
                                    page={viewReport.data.evaluation.page}
                                />
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Evaluation;
