import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Alert from 'sweetalert2';
import moment from 'moment';

const EventCalendar = (props) => {
	// console.log("props", props?.data)
	const eventClick = (eventClick) => {
		// console.log("eventClick", eventClick)
		Alert.fire({
			title: eventClick.event.title,
			html:
				`<div className="table-responsive">
        <table className="table">
        <tbody>
        <tr >
        <td style="text-align:left">Event Title: </td>
        <td style="text-align:left"><strong>` +
				eventClick.event.title +
				`</strong></td>
        </tr>
        <tr >
        <td style="text-align:left; width:25%">Start Time: </td>
        <td style="text-align:left"><strong>
        ` +
				moment(eventClick.event.start).format('DD-MM-yyyy') +
				`
        </strong></td>
        </tr>
        <tr >
        <td  style="text-align:left; width:25%">End Time: </td>
        <td style="text-align:left"><strong>
        ` +
				moment(eventClick.event.end).format('DD-MM-yyyy') +
				`
        </strong></td>
        </tr>
        <tr >
        <td  style="text-align:left; width:25%">Event Type:  </td>
        <td style="text-align:left"><strong>
        ` +
				' ' +
				eventClick.event.extendedProps?.eventType +
				`
        </strong></td>
        </tr>
        </tbody>
        </table>
        </div>`,

			showCancelButton: false,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
		}).then((result) => {
			if (result.value) {
			}
		});
	};

	return (
		<div className='animated fadeIn demo-app'>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className='demo-app-calendar' id='mycalendartest'>
								<FullCalendar
									initialView='dayGridMonth'
									rerenderDelay={10}
									eventDurationEditable={false}
									plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
									events={props.data}
									eventClick={eventClick}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
};
export default EventCalendar;
